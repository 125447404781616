import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.gameURL && _ctx.userId && _ctx.token)
    ? (_openBlock(), _createElementBlock("iframe", {
        key: 0,
        src: `${_ctx.gameURL}?iframe=dontbreakout&person_id=${_ctx.userId}&token=${_ctx.token}`,
        class: "w-screen h-screen",
        allow: "autoplay; fullscreen"
      }, null, 8, _hoisted_1))
    : _createCommentVNode("", true)
}